/**
 * BlogAuthorOverview Page
 * @namespace BlogAuthorOverviewPage
 */
import React, {useEffect, useState} from "react";
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import config from "../../config/main.config";
import {getTokenFromLocalStorage} from "../../helper/util";
import userStore from "../../stores/userStore";
import history from "../../helper/browserHistory";


import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";

async function getArticles(offset) {
    // Get Seminars from Server
    const response = await fetch(config.BASE_URL + 'users/dates/' + offset, {
        method: 'GET',
        headers: {
            "Authorization": "Bearer " + getTokenFromLocalStorage(),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*"
        }
    });
    return await response.json();
}

async function deleteArticle(id) {
    // Get Seminars from Server
    fetch(config.BASE_URL + 'blog/article', {
        method: 'DELETE',
        headers: {
            "id": id,
            "Authorization": "Bearer " + getTokenFromLocalStorage(),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }).then((resp) => resp.json())
        .then(data => {
            if (data.hasOwnProperty('message')) {
                if (data.hasOwnProperty("statusCode")) {
                    return data;
                }
            }
        })
        .catch(function (error) {
            // console.log(error);
        });
    return {}
}

function editArticle(id) {
    history.push('/blog-author-edit/' + id);
}

function createArticle() {
    history.push('/blog-author-edit/0');
}

async function changeCheckbox(published, articleId, setErrorMessage, setIsModalOpen) {
    let body = {published, id: articleId};

    //send changes to server
    const response = await fetch(config.BASE_URL + 'blog/published', {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
            "Authorization": "Bearer " + getTokenFromLocalStorage(),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    })
    const data = await response.json();
    if (!data.success) {
        setErrorMessage(data.message);
        setIsModalOpen(true)
    }
}

function sortHistory(history) {
    let articleHistory = [];
    // Create array with last seven days
    for (let i = 6; i >= 0; i--) {
        let dateToAdd = ((d) => new Date(d.setDate(d.getDate() - i)))(new Date);
        let day = dateToAdd.getDate();
        let month = dateToAdd.getMonth() + 1;

        if (day < 10) {
            day = "0" + day;
        }

        if (month < 10) {
            month = "0" + month;
        }

        let convertedDate = day + "." + month + "." + dateToAdd.getFullYear();
        articleHistory.push({name: convertedDate, seen: 0});
    }

    for (let i = 0; i < history.length; i++) {
        for (let j = 0; j < articleHistory.length; j++) {
            if (articleHistory[j].name === history[i]) {
                articleHistory[j].seen++;
            }
        }

    }
    //console.log(articleHistory);
    return articleHistory;
}


const BlogAuthorOverview = (props) => {
    if (userStore.userFromServer == null) {
        history.push("/");
    }
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [articlesOffset, setArticlesOffset] = useState(0)
    const [articles, setArticles] = useState([]);
    const [errorMessage, setErrorMessage] = useState("")


    useEffect(() => {
        const fetchData = async () => {
            const {articles} = await getArticles(articlesOffset);
            setArticles(articles);
            setLoading(false)
        }
        fetchData();
        if (typeof window !== "undefined") {
            window.scrollTo(0, 0);
        }
    }, []);


    const cArticles = articles.map((article, index) =>
        <Grid.Row columns={2}>
            <Grid.Column>
                <Grid className="article-card" key={index}>
                    <Grid.Row width={16} className="article-header">
                        <h1>{article.header}</h1>
                    </Grid.Row>
                    <Grid.Row width={16} className="article-subtitle">
                        {article.date} | {article.author}
                    </Grid.Row>
                    <Grid.Row width={16} className="article-subtitle">
                        <p>{article.description}</p>
                    </Grid.Row>
                    <Grid.Row columns={2} className="article-subtitle">
                        <Grid.Column>
                            <input type="checkbox"
                                   value="default"
                                   checked={article.published !== 0}
                                   onChange={async () => {
                                       let newArticles = [...articles];
                                       newArticles[index].published = article.published === 1 ? 0 : 1;
                                       setArticles(newArticles);
                                       await changeCheckbox(article.published, article.id, setErrorMessage, setIsErrorModalOpen)

                                   }}
                            /> Veröffentlicht
                        </Grid.Column>
                        <Grid.Column>
                            <p>Gelesen: {article.seen}</p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column className=" overview-button-left">
                            <button className="call-to-action-inverse"
                                    onClick={() => editArticle(article.id)}>
                                <strong> Bearbeiten </strong>
                            </button>
                        </Grid.Column>
                        <Grid.Column className=" overview-button-right">
                            <button className="call-to-action-inverse"
                                    onClick={() => {
                                        deleteArticle(article.id).then(data => {
                                            if (data.message) {
                                                setErrorMessage(data.message)
                                                setIsErrorModalOpen(true)
                                            }
                                            let newArticles = [...articles];
                                            newArticles.splice(index, 1);
                                            setArticles(newArticles);
                                        })
                                    }}>
                                <strong> Löschen </strong>
                            </button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid.Column>
            <Grid.Column>
                <ResponsiveContainer
                    width="100%" height={200}>
                    <LineChart data={sortHistory(article.history)}>
                        <Line type="monotone" dataKey="seen" stroke="#8884d8"/>
                        <CartesianGrid stroke="#ccc"/>
                        <XAxis dataKey="name"/>
                        <Tooltip/>
                        <YAxis allowDecimals={false}/>
                    </LineChart>
                </ResponsiveContainer>
            </Grid.Column>
        </Grid.Row>
    );

    return (
        <div>
            {loading ?
                <Button style={{margin: "auto", display: "contents"}} content={"Loading..."} loading basic
                        size={"massive"}/>
                :
                <Grid>
                    <Grid.Row cloumns={1}>
                        <Grid.Column>
                            <h1 className="page-heading-text">Übersicht</h1>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <div className={"breakline"}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column width={5} floated='right'>
                            <button className="call-to-action-inverse"
                                    onClick={() => createArticle()}>
                                <strong> Artikel Verfassen </strong>
                            </button>
                        </Grid.Column>
                    </Grid.Row>
                    {cArticles.length === 0 ? "" : cArticles}
                    <Grid.Row centered>
                        <button className="call-to-action-inverse"
                                onClick={async () => {
                                    if (typeof window !== "undefined") {
                                        window.scrollTo(0, 0);
                                    }
                                    setLoading(true)
                                    setArticlesOffset(articlesOffset + 10);
                                    const {articles} = await getArticles(articlesOffset + 10);
                                    setArticles(articles);
                                    setLoading(false);
                                }}>
                            <strong> Nächsten 10 Artikel laden </strong>
                        </button>
                    </Grid.Row>
                    <Modal
                        className={"margin-auto"}
                        open={isErrorModalOpen}
                        onClose={() => setIsErrorModalOpen(false)}>
                        <Modal.Header>Fehler</Modal.Header>
                        <Modal.Content>
                            {errorMessage}
                        </Modal.Content>
                        <Modal.Actions>
                            <Button color='black' content='Schließen'
                                    onClick={() => setIsErrorModalOpen(false)}/>
                        </Modal.Actions>
                    </Modal>
                </Grid>
            }
        </div>
    )
}

export default BlogAuthorOverview;